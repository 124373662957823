import PropTypes from 'prop-types'
import { Menu, Transition } from '@headlessui/react';

function ResponsiveNavMenu({username}) {
  const renderUserMenu = () => (
    <>
      <div className="px-4 py-3">
        <p className="text-sm leading-5">Signed in as</p>
        <p className="text-sm font-medium leading-5 text-gray-900 truncate">
          {username}
        </p>
      </div>
      <Menu.Item>
        {({active}) => (
          <a
            href="/shares"
            className={`${
              active
                ? "text-gray-900 bg-gray-100"
                : "text-gray-700"
            } group flex items-center px-4 py-2 text-sm leading-5`}>
            <svg className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
            Shares
          </a>
        )}
      </Menu.Item>
      <Menu.Item>
        {({active}) => (
          <a
            href="/users/edit"
            className={`${
              active
                ? "text-gray-900 bg-gray-100"
                : "text-gray-700"
            } group flex items-center px-4 py-2 text-sm leading-5`}>
            <svg className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            Settings
          </a>
        )}
      </Menu.Item>
      <Menu.Item>
        {({active}) => (
          <span className="flex py-2 px-4 w-full rounded-md shadow-sm">
            <a
              href="/users/sign_out"
              className={`${
                active
                  ? "bg-red-500 border-red-700 shadow-outline-red"
                  : "bg-red-600"
              } whitespace-no-wrap w-full flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white transition ease-in-out duration-150`}>
              Sign out
            </a>
          </span>
        )}
      </Menu.Item>
    </>
  )
  const renderGuestMenu = () => (
    <>
      <Menu.Item>
        {({active}) => (
          <a
            href="/plans"
            className={`${
              active
                ? "text-gray-900 bg-gray-100"
                : "text-gray-700"
            } group flex items-center px-4 py-2 text-sm leading-5`}>
            <svg className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Plans &amp; Pricing
          </a>
        )}
      </Menu.Item>
      <Menu.Item>
        {({active}) => (
          <a
            href="/users/sign_in"
            className={`${
              active
                ? "text-gray-900 bg-gray-100"
                : "text-gray-700"
            } group flex items-center px-4 py-2 text-sm leading-5`}>
            <svg className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
            </svg>
            Sign in
          </a>
        )}
      </Menu.Item>
      <Menu.Item>
        {({active}) => (
          <span className="flex py-2 px-4 w-full rounded-md shadow-sm">
            <a
              href="/users/sign_up"
              className={`${
                active
                  ? "bg-blue-500 border-blue-700 shadow-outline-blue"
                  : "bg-blue-600"
              } whitespace-no-wrap w-full flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white transition ease-in-out duration-150`}>
              Sign up
            </a>
          </span>
        )}
      </Menu.Item>
    </>
  )

  return (
    <Menu>
      {({open}) => (
        <div className="relative">
          <Menu.Button className="p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
            <svg className="h-6 w-6 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </Menu.Button>
          <Transition
            show={open}
            className="absolute z-50 right-0 w-56 mt-2 origin-top-right"
            enter="transition duration-200 ease-out"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition duration-100 ease-in"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items static
              className="bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none">
              {username ? renderUserMenu() : renderGuestMenu()}
            </Menu.Items>
          </Transition>
        </div>
      )}
    </Menu>
  )
}

ResponsiveNavMenu.propTypes = {
  username: PropTypes.string
};

export default ResponsiveNavMenu
